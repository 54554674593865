<template>
  <div style="height: 100%;width: 100%;overflow: hidden;position:relative;">
    <div class="background" :style="backgroundImage"></div>
    <el-container style="width: 100%;height: 100%;position: absolute;">
<!--      <el-aside width="80px"></el-aside>-->
      <el-container>
        <el-header>
          <a-row type="flex" justify="center">
            <a-col :span="21">
              <a-tabs :activeKey="activeKey" @change="callback">
                <a-tab-pane key="1" tab="首页"/>
                <a-tab-pane key="2" tab="服务器列表"/>
                <a-tab-pane key="3" tab="玩家查询"/>
<!--                <a-tab-pane key="4" tab="管服"/>-->
                <a-tab-pane key="5" tab="个人中心"/>
              </a-tabs>
            </a-col>
          </a-row>
        </el-header>
        <el-main style="padding: 0;">
          <keep-alive :include="['index', 'center', 'manage', 'server', 'player']">
            <router-view @changeKey="changeKey"/>
          </keep-alive>
        </el-main>
<!--        <el-footer>Footer</el-footer>-->
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      activeKey: '1',
      collapsed: true,
      pathMap:{
        '1':'/index',
        '2':'/server',
        '3':'/player',
        '4':'/manage',
        '5':'/center'
      }
    }
  },
  computed: {
    backgroundImage() {
      let images = [
        'https://pic.imgdb.cn/item/6537f1a2c458853aef03b7a1.jpg',
        'https://pic.imgdb.cn/item/6537f2f5c458853aef08bb20.jpg',
        'https://pic.imgdb.cn/item/6537f364c458853aef0a53f6.jpg',
        'https://pic.imgdb.cn/item/6537f380c458853aef0ab6cc.jpg',
        'https://pic.imgdb.cn/item/6537f4c4c458853aef0f1c4f.jpg'
      ]
      let image = images[Math.floor(Math.random() * 5)]; //进行计算随机
      return "background-image:url('"+ image +" ');"
    }
  },
  methods: {
    callback(key) {
      this.activeKey = key
      this.$router.push(this.pathMap[key]);
    },
    changeKey(key) {
      this.activeKey = key
    }
  }
}
</script>

<style scoped>
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 3px;
}

.background {
  background-repeat: no-repeat;
  background-position: center;
  background-attachment:fixed;
  background-size:cover;
  filter: blur(15px);
  transform: scale(1.2);
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
}
::v-deep .ant-tabs-ink-bar {
  background-color: white;
}
::v-deep .ant-tabs-nav .ant-tabs-tab:hover {
  color: white;
}
::v-deep .ant-tabs-nav .ant-tabs-tab-active {
  color: white;
  text-shadow: 0 0 10px rgba(255, 255, 255, 1);
}
::v-deep .ant-tabs-bar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
::v-deep .ant-tabs {
  color: white;
}
::v-deep .ant-tabs-nav-container {
  font-size: 1.4em;
}
::v-deep .ant-tabs-tab-next, ::v-deep .ant-tabs-tab-prev{
  color: white;
}

#components-layout-demo-basic .ant-layout-header {
  background: rgba(255, 255, 255, 0);
  /*background: #7dbcea;*/
  color: #fff;
}
#components-layout-demo-basic .ant-layout-sider {
  background: rgba(255, 255, 255, 0);
  /*background: #3ba0e9;*/
  color: #fff;
  /*line-height: 120px;*/
  width: 100px;
}
#components-layout-demo-basic .ant-layout-content {
  background: rgba(255, 255, 255, 0);
  /*background: rgba(16, 142, 233, 1);*/
  color: #fff;
  min-height: 120px;
  line-height: 120px;
}
#components-layout-demo-basic > .ant-layout {
  margin-bottom: 48px;
}
#components-layout-demo-basic > .ant-layout:last-child {
  margin: 0;
}
.ant-layout {
  background: rgba(255, 255, 255, 0);;
}

/** <576px **/
@media screen and (max-width: 576px) {
  ::v-deep .ant-tabs-tab {
    font-size: 15px;
    padding: 14px 0;
  }
}
/** ≥576px **/
@media screen and (min-width: 576px) {
  ::v-deep .ant-tabs-tab {
    font-size: 20px;
    padding: 14px 0;
  }
}
/** ≥768px **/
@media screen and (min-width: 768px) {

}
/** ≥992px **/
@media screen and (min-width: 992px) {

}
/** ≥1200px **/
@media screen and (min-width: 1200px) {
  ::v-deep .ant-tabs-tab {
    font-size: 22px;
    padding: 12px 16px;
  }
}
/** ≥1600px **/
@media screen and (min-width: 1600px) {

}
</style>